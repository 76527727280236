import { useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import SimpleBar from 'simplebar-react';
import { genericSKUBreakdown, getConditionNameBySymbol } from "../../../../common/helpers/product";
import { useConditionsQuery } from "../../../../services/condition";
import ProductTitle from "../../../../Components/Common/ProductTitle";
import { useEffect, useState } from 'react';
import { DEFAULT_PER_PAGE } from '../../../../common/constants';
import { useParams } from "react-router-dom";
import BPagination from '../../../../Components/Common/BPagination';

import {
  useStocktakeItemsQuery,
  useLazyStocktakeItemsQuery,
  useStocktakeItemsCreateMutation,
  useStocktakeItemsUpdateMutation,
  useStocktakeItemsDeleteMutation
} from '../../../../services/stocktakeitems';
import BDropdown from "../../../../Components/Common/BDropdown";
import { setFilter } from "../../../../store/slice/stocktake";
import { useDispatch } from "react-redux";

const ScannedItems = props => {
  const dispatch = useDispatch();
  const { data: conditions_data } = useConditionsQuery({ per_page: 1000 });
  const stocktake = useSelector(store => store.stocktake);
  const scanned_items = stocktake.scanned_items ?? {};
  const scanned_items_keys = Object.keys(scanned_items);

  const [status_filter, setStatusFilter] = useState({ value: 0, label: 'All' });
  const [search, setSearch] = useState(null);

  useEffect(() => {
    setStatusFilter({ value: stocktake.filters.is_existing, label: ['All', 'Scanned', 'Not Found'][stocktake.filters.is_existing ?? 0] });
  }, [stocktake.filters.is_existing])

  const handleSearch = () => {
    dispatch(setFilter({ status: 'search', value: search }));
  };

  const handleSetStatusFilter = (status) => {
    setStatusFilter(status);
    let value = 0
    if (status.value === 0) {
      value = undefined;
    } else if (status.value === 1) {
      value = 1;
    }

    dispatch(setFilter({ status: 'is_existing', value }));
  }
  return (
    <Row>
      <SimpleBar style={{ maxHeight: '500px' }}>
        <Col lg={12}>
          <div className="col-sm" style={{ padding: '10px' }}>
            <div className="d-flex justify-content-sm-end">
              <div className="ms-2">
                  <BDropdown
                    value={status_filter}
                    handleSetValue={handleSetStatusFilter}
                    options={[
                      { value: 0, label: 'All' },
                      { value: 1, label: 'Scanned' },
                      { value: 2, label: 'Not Found' }
                    ]}
                  />
              </div>
              <div className="search-box ms-2 col-md-3 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value || undefined);
                    }}
                    placeholder="Search by Title or Generic SKU"
                    value={search || ''}
                  />
                  <i className="ri-search-line search-icon"></i>
                </form>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2 ms-2">
                <Button
                  color="secondary"
                  outline
                  onClick={handleSearch}
                  className="custom-toggle active"
                >
                  <span className="icon-on">
                    <i className="ri-search-line search-icon align-bottom me-1"></i>
                    Search
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <table
            className="table align-middle table-nowrap table-striped-columns"
            id="table_stocktake"
          >
            <thead className="table-light bg-light sticky-top">
              <tr>
                <th scope="col" style={{ width: '23 %' }}>
                  Product
                </th>
                <th
                  scope="col"
                >
                  Size
                </th>
                <th scope="col">Condition</th>
                <th scope="col">Count</th>
                <th scope="col" style={{ display: 'none' }}></th>
              </tr>
            </thead>
            <tbody>
              {
                (scanned_items_keys || []).filter(item => {
                  if (status_filter.value === 1) {
                    return scanned_items[item].is_existing;
                  } else if (status_filter.value === 2) {
                    return !scanned_items[item].is_existing;
                  }
                  return true;
                }).map(item => {
                  const gsku = genericSKUBreakdown(item);
                  const item_data = scanned_items[item];
                  return (
                    <tr key={item}>
                      <td>
                        <div className="d-flex justify-content-between">
                          <ProductTitle
                            generic_sku={item}
                            title={scanned_items[item].title}
                            style={{ maxWidth: '350px' }}
                          />

                          {(!scanned_items[item].is_existing) && <i className="ri-error-warning-line text-warning"></i>}
                        </div>
                      </td>
                      <td>{gsku.size ?? '-'}</td>
                      <td>{getConditionNameBySymbol(gsku.condition, conditions_data?.data) || '-'}</td>
                      <td>{scanned_items[item].quantity ?? '-'}</td>
                      <td className="text-center" style={{ display: 'none' }}>
                        <ButtonGroup size="sm">
                          <Button color="primary" className="btn-icon" outline><i className="ri-subtract-line" /></Button>
                          <Button color="primary" className="btn-icon" outline><i className="ri-add-line" /></Button>
                        </ButtonGroup>
                        <Button size="sm" color="danger" className="ms-1 btn-icon"><i className="ri-delete-bin-line" /></Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Col>
      </SimpleBar>
    </Row>
  );
}

export default ScannedItems;