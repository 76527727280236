import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import SimpleBar from 'simplebar-react';
import ProductTitle from "../../../../Components/Common/ProductTitle";
import { useState } from 'react';

const InventoryView = () => {
  const stocktake = useSelector(store => store.stocktake);
  const scanned_items = stocktake.scanned_items ?? {};
  const scanned_items_keys = Object.keys(scanned_items);

  // const [search_param, setSearchParam] = useState('');
  const [search, setSearch] = useState(null);

  const handleSearch = () => {
    // setSearchParam(search);
  };

  return (
    <Row>
      <SimpleBar style={{ maxHeight: '500px' }}>
        <Col lg={12}>
          <div className="col-sm" style={{ padding: '10px' }}>
            <div className="d-flex justify-content-sm-end">
              <div className="search-box ms-2 col-md-3 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value || undefined);
                    }}
                    placeholder="Search by Title or Generic SKU"
                    value={search || ''}
                  />
                  <i className="ri-search-line search-icon"></i>
                </form>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2 ms-2">
                <Button
                  color="secondary"
                  outline
                  onClick={handleSearch}
                  className="custom-toggle active"
                >
                  <span className="icon-on">
                    <i className="ri-search-line search-icon align-bottom me-1"></i>
                    Search
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <table
            className="table align-middle table-nowrap table-striped-columns"
            id="table_stocktake"
          >
            <thead className="table-light bg-light sticky-top">
              <tr>
                <th scope="col" style={{ width: '23 %' }}>
                  Product
                </th>
                <th
                  scope="col"
                >
                  Quantity on Hand
                </th>
                <th scope="col">Physical Count</th>
                <th scope="col">Variances</th>
              </tr>
            </thead>
            <tbody>
              {
                (scanned_items_keys || []).map(item => {
                  const item_data = scanned_items[item];
                  const variance = (scanned_items[item].quantity) - (item_data.on_hand_quantity || 0);
                  const variance_class = variance > 0 ? 'text-info' : variance < 0 ? 'text-success' : '';
                  return (
                    <tr key={item}>
                      <td>
                        <ProductTitle
                          generic_sku={item}
                          title={scanned_items[item].title}
                          style={{ maxWidth: '350px' }}
                        />
                      </td>
                      <td>{item_data.on_hand_quantity || 0}</td>
                      <td>{scanned_items[item].quantity}</td>
                      <td><span className={variance_class}>{variance}</span></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Col>
      </SimpleBar>
    </Row>
  );
}

export default InventoryView;