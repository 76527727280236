import { useState } from "react";
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const BDropdown = (props) => {
  const [ is_open_status_dropdown, setIsOpenStatusDropdown ] = useState(false);
  const {
    value,
    options,
    
    
    handleSetValue,
  } = props;
  return (
      <ButtonGroup className="material-shadow w-100">
        <Dropdown
          isOpen={is_open_status_dropdown}
          toggle={() =>
            setIsOpenStatusDropdown(!is_open_status_dropdown)
          }
          className=""
        >
          <DropdownToggle
            tag="button"
            className="btn btn-light material-shadow-none"
          >
            {value.label}
            <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu className="notify-item language py-2" container="body">
            {options.map((o, i) => {
              if (o.type === 'divider')
                return <DropdownItem key={`divider_${i}`} divider />;
              return (
                <DropdownItem
                  key={o.value}
                  onClick={() => {handleSetValue(o)}}
                  className={`${
                    value === o.value ? 'active' : 'none'
                  }`}
                >
                  <span className="align-middle">{o.label}</span>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </ButtonGroup>
  );
}

export default BDropdown;